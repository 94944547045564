/* :root {
  --color1: #000000;
  --color2: #333333;
  --color3: rgb(227, 227, 227);
  --color4: #6200EE;
} */

.projectsContainer {
  margin-top: 25px;
  margin-bottom: 75px;
  padding-bottom: 60px;
}

.projectsHeaders {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projectsHeader2 {
  font-size: 1.5em;
}

.projectsCardsContainer {
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: minimax(100px, auto);
  justify-items: center;
  /* align-items: center; */
}

.projectsCardGroup {
  width: 75%;
  border-radius: 15px;
  margin: 25px;
}

.projectsCardBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-around;
}

.projectsCardElement {
  border: none;
  padding: 0px;
}

.synthCard {
  grid-column: auto / span 2;
}

.movieAppCard {
  grid-column: auto / span 2;
}

.chatAppCard {
  grid-column: auto / span 2;
}

.todoCard {
  grid-column: auto / span 2;
}

.apiCard {
  grid-column: auto / span 2;
}

.imageContainer {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 60%;
}

.projectsCardImage {
  margin: 15px;
  width: 70%;
}

.synthImageMain {
  width: 100%;
}

.synthImageSecond {
  width: 100%;
}

.synthImageContainer {
  padding: 0;
  /* padding-left: 10px;
  padding-right: 10px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
}

.todoImage {
  width: 80%;
}

.bgColor {
  background-color: var(--color3);
  color: var(--color2);
}

.linkBtn {
  color: var(--color3);
  background-color: var(--color2);
  border: var(--color3);
}

.linkBtn:hover {
  background-color: var(--color1);
}

@media (max-width: 1250px) {
  .projectsCardsContainer {
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minimax(100px, auto);
    justify-items: center;
  }
}

@media (max-width: 800px) {
  .projectsCardsContainer {
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minimax(100px, auto);
    justify-items: center;
  }
}