/* :root {
  --color1: #000000;
  --color2: #333333;
  --color3: rgb(227, 227, 227);
  --color4: #6200EE;
} */

.bgColor {
  background-color: var(--color3);
}

.aboutContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 9%;
  width: 100%;
}

.aboutCardGroup {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  border-radius: 15%;
  width: 75%;
}

.aboutCard {
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutCardTitle {
  font-size: 45px;
  font-weight: bold;
}

.aboutText {
  font-size: larger;
}

.aboutDownloadBtn {
  background-color: var(--color2);
  border-color: var(--color2);
}

.aboutDownloadBtn:hover {
  color: var(--color3);
  background-color: var(--color4);
  border-color: var(--color4);
}

.aboutBodyLinks {
  display: flex;
  flex-direction: row;
}

@media (max-width: 500px) {

  .aboutContainer {
    padding-top: 4%;

  }

  .aboutCardGroup {
    width: 85%;
  }

  .aboutCard {
    padding: 20px;
  }
  
}