/* :root {
  --color1: #000000;
  --color2: #333333;
  --color3: rgb(227, 227, 227);
  --color4: #6200EE;
} */

.navColors {
  text-decoration: none;
  background-color: var(--color1);
  color: var(--color3);
}

.navColors:focus,
.navColors:active {
  color: var(--color3);
}

/* :hover cannot be used on a catch all class or it causes issues */
.navName:hover,
.navHome:hover,
.navProjects:hover,
.navAbout:hover {
  color: var(--color4);
}

/* .nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
} */

.navBrand {
  padding-left: 25px;
  font-size: 2em;
}

.navLink {
  font-size: 1.2em;
}

@media (max-width: 500px) {

  .nav {
    align-items: center;
    justify-content: center;
  }

  .navName:hover,
  .navHome:hover,
  .navProjects:hover,
  .navAbout:hover {
    color: var(--color3);
  }

  .navBrand {
    display: none;
  }

}