/* :root {
  --color1: #000000;
  --color2: #333333;
  --color3: rgb(227, 227, 227);
  --color4: #6200EE;
} */

/* .footer-container {
  border-style: solid;
  border-color: rgb(253, 251, 251);
} */

.footer {
  border-top: solid;
  border-color: rgb(22, 22, 22);
  background-color: var(--color1);
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.github-link {
  text-decoration: underline;
  color: var(--color3);
}

.github-link:hover {
  color: var(--color4);
}