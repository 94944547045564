:root {
  --color1: #000000;
  --color2: #333333;
  /* --color3: #0d6efd; */
  --color3: rgb(227, 227, 227);
  --color4: #6200EE;
}

.mainContainer {
  padding-top: 200px;
}

.splashContainer {
  margin: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.splashText {
  font-size: 60px;
}

.projectsLink {
  text-decoration: none;
  color: var(--color4);
}

.projectsLink:hover {
  text-decoration: underline;
  color: var(--color4);
}

Button {
  margin: 10px;
}

@media (max-width: 500px) {

  .mainContainer {
    padding-top: 15px;
  }

  .splashText {
    font-size: 45px;
  }

}