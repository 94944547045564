@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

:root {
  --color1: #000000;
  --color2: #333333;
  --color3: rgb(227, 227, 227);
  --color4: #6200EE;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--color1);
  color: var(--color3);
  font-family: 'Lato', sans-serif;
}

