/* :root {
  --color1: #000000;
  --color2: #333333;
  --color3: rgb(227, 227, 227);
  --color4: #6200EE;
} */

.bgColor {
  background-color: var(--color3);
}

.contactContainer {
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardGroup {
  width: 75%;
}

.cardElement {
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardTitle {
  padding-top: 25px;
  font-size: 45px;
  font-weight: bold;
}

.contactText {
  font-size: larger;
}

.emailText {
  /* color: var(--color); */
  font-size: x-large;
  font-weight: bold;
}

.emailComponentContainer {
  width: 100%;
}

.cardBodyLinks {
  display: flex;
  flex-direction: row;
}

.linksContainer {
  display: flex;
  flex-direction: row;
}

.sendEmailBtn {
  background-color: var(--color2);
  border-color: var(--color2);
}

.downloadBtn {
  background-color: var(--color2);
  border-color: var(--color2);
}

.downloadBtn:hover,
.sendEmailBtn:hover {
  color: var(--color3);
  background-color: var(--color4);
  border-color: var(--color4);
}

@media (max-width: 500px) {

  .contactContainer {
    padding-top: 8%;
    padding-bottom: 15%;
  }

  .cardGroup {
    width: 85%;
  }

}